<template>
  <div 
    id="events"
    class="mx-n2"
  >
    <v-tabs
      v-model="controller.tabs.selected"
      background-color="transparent"
      color="primary"
      fixed-tabs
    >
      <v-tab>
        Próximos
      </v-tab>
      <v-tab>
        Passados
      </v-tab>
    </v-tabs>
    <v-divider class="ma-0" />

    <v-tabs-items 
      v-model="controller.tabs.selected"
      class="content fill-height"
      style="background: transparent;"
    >
      <v-tab-item 
        v-for="tab in controller.tabs.items"
        :key="'tab-'+tab"
        class="scrollable fill-height pa-4"
      >
        <v-card
          v-for="event in list[tab]"
          :key="'event-'+event.id"
          :elevation="event.status=='active' ? 8 : event.status=='future' ? 4 : 2"
          :disabled="event.status=='past'"
          class="item mb-4"
          :class="[event.status]"
        >
          <v-list>
            <h3 
              :class="[(event.status=='past' ? 'grey' : 'secondary')+'--text']"
              class="text-h5 font-weight-medium px-4 py-2"
            >
              {{ event.title }}
            </h3>
            <!-- <v-divider class="mx-4" /> -->
            <v-list-item
              v-for="(brief, key) in event.briefing"
              :key="'event-'+event.id+'-field-'+key"
              :class="{ 'd-none': brief.hidden }"
            >
              <v-list-item-content>
                <v-list-item-subtitle class="text-overline mb-2">{{ brief.text }}</v-list-item-subtitle>
                <v-list-item-title 
                  class="text text-untruncate"
                  :class="[('class' in brief ? brief.class : '')]"
                  v-html="brief.value" 
                />
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider 
            v-if="event.status!='past'" 
            class="mx-4"
          />
          <v-card-actions 
            v-if="event.status!='past'"
          >
            <v-btn
              text
              color="warning"
              @click="report(event.id, event.title)"
            >
              Reportar problema
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-overlay 
      v-model="loading"
      color="grey darken-4"
      absolute
      opacity=".9"
      z-index="2"
      class="loading-overlay text-center pt-8"
    >
      <loading class="loading mb-4" />
      <span class="d-block text-overline grey--text mb-2">
        Carregando
      </span>
    </v-overlay>
  </div>
</template>

<style type="text/css">

  #events.sheet, #events .items {
    background: var(--v-background-base);
    min-height: 100%;
  }
  #events .scrollable {
    max-height: calc(100vh - 104px);
  }
  #events .item a {
    color: var(--v-secondary-base);
    font-weight: 500;
  }
  #events .item .text {
    line-height: 1.4;
  }

  #events .empty-state .text {
    opacity: .75;
  }

  #events .loading-overlay .loading {
    width: 48px;
    height: 48px;
    opacity: .8;
  }


  
</style>

<script>
  import { 
    mdiHelpCircleOutline, 
    mdiClose,
    mdiChevronLeft,
    mdiChevronRight,
    mdiGauge,
    mdiInformationOutline
  } from '@mdi/js';
  import { events } from '@/dictionary'
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'
  const moment = require('moment');
  import device from 'mobile-device-detect';


  export default {
    name: 'Events',

    props: {
      tickets: {
        type: Object,
        default: () => {}
      },
      selected: {
        type: [String, Number],
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiHelpCircleOutline,
        mdiClose,
        mdiChevronLeft,
        mdiChevronRight,
        mdiGauge,
        mdiInformationOutline
      },
      controller: {
        tabs: {
          selected: 0,
          items: ['active', 'past']
        },
        brief: {
          state: {
            'active': ['directions', 'meeting_point', 'route', 'obs'],
            'future': ['date', 'location', 'meeting_point', 'obs', 'route', 'reward'],
            'past': ['date', 'location', 'reward'],
          },
          fields: [
            { 
              key: 'date', 
              start: 'mobilizesAt', 
              end: 'endsAt',
              text: 'Data início - término' 
            },  
            { 
              key: 'reward', 
              text: 'Remuneração',
              prefix: 'R$ ',
              omit: null
            }, 
            { 
              key: 'obs', 
              text: 'Observações',
              omit: null
            }, 
            { 
              key: 'location', 
              text: 'Local'
            }, 
            {
              key: 'directions', 
              text: '🟢 Valendo!',
              class: 'text-body-1',
              dates: ['startsAt', 'endsAt'],
              omit: null
            }, 
            { 
              key: 'meeting_point', 
              text: 'Ponto de Encontro',
              title: 'Ver Ponto de Encontro no mapa',
              address: true,
              omit: null
            }, 
            { 
              key: 'route', 
              text: 'Trajeto',
              title: 'Ver Trajeto no mapa',
              address: true,
              omit: null
            }
          ]
        },
      },
      loader: {
        loading: false,
        updatedAt: null,
        lazyness: [60, 'minutes']
      },
      dictionary: events
    }),

    computed: {
      events: sync('events/data@items'),
      // selected: sync('events/data@selected'),
      updatedAt: sync('events/data@updatedAt'),
      buzzer: sync('app/buzzer'),
      user: sync('user'),
      toast: sync('app/toast'),

      list () {
        const events = this.events;
        const lists = _.groupBy(_.map(events, event => {
          const status = this.eventStatus(event);
          return {
            ...event,
            status,
            title: this.dictionary[event.type] + ' ' + event.title,
            briefing: this.briefing({ ...event, status })
          };
        }), event => event.status=='past' ? 'past' : 'active');
        return _.mapValues(lists, (list, status) => {
          return _.orderBy(list, ['status', 'mobilizesAt'], ['asc', status=='past' ? 'desc' : 'asc'])
        });
      },

      hasActive () {
        return _.some(this.list, ['status', 'active']);
      },

      openedTickets () {
        return _.omitBy(this.tickets, 'closed');
      },

      pendingInvites () {
        const events = _.pickBy(this.events, event => this.eventStatus(event)=='future');
        const event_ids = _.keys(events);
        const tickets = this.openedTickets;
        return this.flashmobTickets(tickets, event_ids);
      }
    },

    filters: {
      formatDate (v) {
        return moment(v).format('DD/MM');
      }
    },

    methods: {
      ...services,

      briefing (event) {
        const controller = _.clone(this.controller.brief);
        const fields = _.cloneDeep(controller.fields);
        const state = _.has(controller.state, event.status) ? controller.state[event.status] : _.map(fields, 'key');
        return _.mapValues(_.pick(_.keyBy(fields, 'key'), state), (field, f) => {
          switch (f) {
            case 'directions':
              const date = _.find(field.dates, d => {
                return moment().isBefore(event[d]);
              });
              let text;
              switch (date) {
                case 'startsAt':
                  text = `*Estacione às ${moment(event.mobilizesAt).format('HH:mm')} no Ponto de Encontro*. \n\nAguarde o início do Trajeto, que começará às ${moment(event.startsAt).format('HH:mm')}.`
                  field.value = text;
                  break;

                case 'endsAt':
                  text = `*Siga o Trajeto entre ${moment(event.startsAt).format('HH:mm')} e ${moment(event.endsAt).format('HH:mm')}*.`
                  field.value = text;
                  break;
                  
                default:
                  text = `*Estacione novamente no Ponto de Encontro* e aguarde a desmontagem do equipamento.`
                  field.value = text;
                  break;
              }

              break;
            
            case 'date':
              const start = event[field.start];
              const end = event[field.end];
              field.value = moment(start).isValid() ? moment(start).format('DD/MM HH:mm') + (moment(end).isValid() ? ' - ' + moment(end).format('HH:mm') : '') : 'A definir';
              break;

            default:
              const value = event[f];
              field.value = !!value ? ('prefix' in field ? field.prefix : '') + value : 'A definir';
              field.hidden = value==field.omit;
              if (!!value&&_.has(field, 'address')) {
                console.log('device', device)
                const isLink = /^(http|maps|geo)(.?):/gi.test(value);
                const link = isLink ? value : (device.isAndroid=='ios' ? 'geo' : 'maps')+':?q='+value
                field.value = `[${isLink ? field.title : value}](${link})^`
              }
              break;
          }
          field.value = this.formatText(field.value);
          return field;
        });
      },

      eventStatus (event) {
        return !!event.demobilizesAt&&moment().isAfter(event.demobilizesAt) ? 'past' : 
          !!event.mobilizesAt&&moment().add(1,'h').isAfter(event.mobilizesAt)&&!!event.demobilizesAt&&moment().isBefore(event.demobilizesAt) ? 'active' : 
            event.mobilizesAt==null||moment().isBefore(event.mobilizesAt) ? 'future' : undefined;
      },

      flashmobTickets (tickets, events) {
        return _.filter(tickets, ticket => {
          return ticket.section=='flashmob'||(_.has(ticket, 'data')&&_.has(ticket.data, 'id_flashmob')&&_.indexOf(events, ticket.data.id_flashmob)>=0);
        });
      },

      checkPendingInvites () {
        const invites = this.pendingInvites;
        if (_.size(invites)>0) {
          this.$emit('open-ticket', _.first(invites).id);
        }
      },
      
      getData (cpf, token) {
        this.get(cpf, token);
      },

      shouldUpdate () {
        const loader = this.loader;
        console.log(loader.updatedAt, moment(loader.updatedAt).add(...loader.lazyness));
        return (!loader.loading && loader.updatedAt==null) || moment().isAfter(moment(loader.updatedAt).add(...loader.lazyness));
      },

      report (id, title) {
        const tickets = _.orderBy(this.flashmobTickets(this.tickets, [id]), ['createdAt'], ['asc']);
        const ticket = _.size(tickets)>0 ? _.last(tickets) : null;
        const config = {
          data: {
            title
          },
          ...(_.isNil(ticket) ? { 
            section: 'flashmob',
            category: {
              value: 'Parceria',
              child: 'Flashmob'
            }
          } : {})
        };
        this.$emit('report', ticket.id, config);
      },

      get (cpf, token) {
        const loader = this.loader;
        const update = this.shouldUpdate();
        loader.loading = true;
        if (!update) {
          setTimeout((loader) => {
            loader.loading = false;
          }, 500, loader);
        }

        const data = {
          cpf,
          token,
        };
        
        console.log('events.get...', data);
        this.$api
          .get('driver/events/', { params: data })
          .then(response => {
            console.log('events.get => ',response);

            this.events = Object.assign({}, _.keyBy(_.map(response.data, item => {
              return this.format(item, this.dictionary);
            }), 'id'));
            
            this.checkPendingInvites()
          })
          .catch(error => {
            if(error.response.status==401){
              this.getout();
              this.handleError(error, 'Sua sessão expirou...');
            }else{
              this.handleError(error, 'Aguardando resposta do sistema...');
              setTimeout(($, cpf, token) => {
                $.get(cpf, token);
              }, 5000, this, cpf, token);
            }
            loader.updatedAt = moment().valueOf();
          })
          .finally(() => {
            loader.loading = false;
          });
      },
    },

    mounted () {
      this.loader.updatedAt = this.updatedAt;
      this.getData(this.rawCPF(this.user.cpf), this.user.auth.token);
    },

    components: {
      Loading: () => import('@/components/IconLoading'),
    },
  }
</script>
